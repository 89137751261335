import React, { useEffect } from 'react';

import Glide, { Controls, Breakpoints, Keyboard, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import NextIcon from '../../svg/Right_scroll';
import PrevIcon from '../../svg/Left_scroll';
import ArrowNextIcon from '../../svg/Next_icon';
import ArrowPrevIcon from '../../svg/Prev_icon';
// import '@glidejs/glide/dist/css/glide.core.min.css';
import './style.css'

interface ICarousel {
  perView?: {
    lg: number,
    md: number
  },
  initClass?: string,
  carouselLength?: number
}

const Carousel: React.FC<ICarousel> = ({ children, perView = { lg: 3, md: 2 }, initClass, carouselLength = 0 }) => {

  useEffect(() => {
    new Glide(`.glide-${initClass}`, {
      type: "slider",
      perView: perView.lg,
      breakpoints: {
        1024: {
          perView: perView.md
        },
        640: {
          perView: 1,
          peek: {
            before: 30,
            after: 30
          }
        }
      }
    }).mount({ Controls, Breakpoints, Keyboard, Swipe })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="">
      <div className={`glide-${initClass} relative`}>
        {carouselLength > 2  && (
          <div data-glide-el="controls" className="hidden lg:flex justify-between absolute right-0 left-0 top-1/2 z-10">
            <button data-glide-dir="<" className="focus:outline-none"><PrevIcon /></button>
            <button data-glide-dir=">" className="focus:outline-none"><NextIcon /></button>
          </div>
        )}
        {carouselLength > 1 && (
          <div data-glide-el="controls" className="flex justify-end lg:hidden">
            <button data-glide-dir="<" className="focus:outline-none"><ArrowPrevIcon /></button>
            <button data-glide-dir=">" className="focus:outline-none"><ArrowNextIcon /></button>
        </div>
        )}
        <div data-glide-el="track" className={`glide__track ${carouselLength > 1 && 'lg:w-11/12'} mx-auto`}>
          <div className={`glide__slides`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel