import React from 'react'

interface IRightScroll {
  onClick?: () => void
}

const RightScroll: React.FC<IRightScroll> = (props) => {
  return (
    <svg width="19px" onClick={props.onClick} height="34px" viewBox="0 0 19 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/card/lright-scroll</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="icon/card/right-scroll" fill="#727272" fillRule="nonzero">
              <g id="icon/card/lright-scroll">
                  <path d="M18.4192336,17.8608158 L2.14760283,33.6409434 C1.91258216,33.8707417 1.59266204,34 1.25892146,34 C0.925180878,34 0.605260761,33.8707417 0.370240086,33.6409434 C0.133284274,33.4130218 0,33.1027653 0,32.7791057 C0,32.4554461 0.133284274,32.1451896 0.370240086,31.917268 L15.7657061,16.998978 L0.370240086,2.08068812 C0.0527448109,1.77278315 -0.0712512087,1.32400189 0.0449601275,0.903395876 C0.161171464,0.482789866 0.499934889,0.154259175 0.9336415,0.0415581343 C1.36734811,-0.0711429063 1.83010756,0.0491076725 2.14760283,0.357012642 L18.4192336,16.1371403 C18.6561894,16.3650619 18.7894737,16.6753185 18.7894737,16.998978 C18.7894737,17.3226376 18.6561894,17.6328942 18.4192336,17.8608158 Z" id="Path"></path>
              </g>
          </g>
      </g>
    </svg>
  )
}

export default RightScroll;
