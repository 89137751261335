import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { CountryPagePropsType, lang } from '../../entities/queriesTypes'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { useGlobalStateContext } from '../../Context/site';
import PageSection from '../../components/organism/HomePageComponent';
import translations from '../../entities/translations';
import { Card } from '../../components/molecule/Card';
import { buildImageFocalPoint, getCarouselView, getCountryPageTranslation as gCPT } from '../../utils';
import Image from "../../components/atoms/Image";
import Button from "../../components/atoms/Button";
import Carousel from "../../components/molecule/Glidejs";
import GalleryCarousel from "../../components/molecule/Glidejs/Gallery";
import { countrySubMenu } from '../../utils/subNavigation'
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";
import Link from "../../components/atoms/Link";

const IndexPage:React.FC<CountryPagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext.locale
  const pageHash = translations.countryMenuID;
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.country,
    subNavigationItem: countrySubMenu,
    subNavigationTitle: undefined
    })
  const { state } = useGlobalStateContext();
  const pageID = translations.globals.subMenuTranslation;
  const buttonTransaltion = translations.globals.buttons;
  const _regionTranslation = translations.CountryPages.find((item) => item.menu === 'Orte')!;


  return (
    <Layout seoTitle={(page?.metadata?.page_title && page?.metadata?.page_title[state?.lang as lang]) as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : ''},
    ]}>
      <Wrapper className="py-12" id={pageHash?.Uberblick?.[state?.lang as lang]!}>
        { page?.countryOverViewIntroCopy?.[state?.lang as lang] &&
         <PortableTextRenderer body={page?.countryOverViewIntroCopy?.[state?.lang as lang]} />}

          {page?.countryOverviewGallery?.pictures?.length && 
            <div className="w-10/12 my-10">
              <GalleryCarousel initClass='countryOverviewGallery'>
                {page.countryOverviewGallery?.pictures?.map((item, i: number) => (
                  <Image alt={(item?.alt && item?.alt[state?.lang as lang]) as string} src={buildImageFocalPoint(item?.image?.asset) as string} key={i} className="glide__slide object-cover w-full lg:h-large" />
                ))}
              </GalleryCarousel>
            </div>}

          {page?.countryOverViewOutroCopy?.[state?.lang as lang]
          && <PortableTextRenderer body={page?.countryOverViewOutroCopy?.[state?.lang as lang]} />}

        <Link type="internal" to={`/${state?.lang}/country/${pageContext?.allSlugs 
          && pageContext?.allSlugs.country && pageContext?.allSlugs.country[state?.lang as lang]?.current}/${gCPT({ view: CurrentView.highlights, lang: state?.lang as lang })}#`}>
          <Button color='btn-secondary' size="btn-large" >
            {buttonTransaltion['Erfahren Sie hier mehr über uns'][state?.lang as lang]}
          </Button>
        </Link>
      </Wrapper>

      <PageSection 
        readMoreLink={`/${state?.lang}/country/${pageContext?.allSlugs 
          && pageContext?.allSlugs.country && pageContext?.allSlugs.country[state?.lang as lang]?.current}/${gCPT({ view: CurrentView.highlights, lang: state?.lang as lang })}#`}
        sanityContext={page?.highlightsIntroduction?.[state?.lang as lang]}
        id={``} layerBackground="bg-white-smoke"
        idTitle={pageID.Hohepunkte?.[state?.lang as lang]!}
        title={state?.countryName!} >
        <Carousel initClass='Uberblick' perView={getCarouselView(page?.countryHighlights?.length)} carouselLength={page?.countryHighlights?.length}>
         {page?.countryHighlights?.map((item, i: number) => <Card
         uniqueRef={item?.['_id']!}
          key={i}
           _className="mt-2 mx-2.5 w-10/12 mb-4 glide__slide"
          imageAlt={(item?.regionImage?.alt && item?.regionImage?.alt[state?.lang as lang]) as string}
          imageUrl={buildImageFocalPoint(item.regionImage?.image)}
          title={(item?.title && item?.title[state?.lang as lang]) as string || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="COUNTRY"
          ctaUrl={`/${state?.lang}/${item?.parentCountry?.slug && 
            item?.parentCountry?.slug[state?.lang as lang]?.current}/${_regionTranslation.slug[state?.lang as lang]}/${item?.slug 
              && item?.slug[state?.lang as lang]?.current}#${pageID.Orte[state?.lang as lang]!}`}
          cardSize={'SMALL'}
          /> )}
        </Carousel>
      </PageSection>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryOverviewPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      heroImage: _rawCountryOverviewImage(resolveReferences: {maxDepth: 2})
      countryOverViewIntroCopy: _rawCountryOverviewIntroCopy(resolveReferences: {maxDepth: 4})
      countryOverViewOutroCopy: _rawCountryOverviewOutroCopy(resolveReferences: {maxDepth: 4})
      highlightsIntroduction: _rawHighlightsIntroduction(resolveReferences: {maxDepth: 1})
      countryHighlights {
        _id
        id: _id
        _key
        regionImage: _rawRegionImage(resolveReferences: {maxDepth: 2})
        title {
          de
         
        }
        slug {
          de {
            current
          }

        }
        parentCountry {
          slug {
            de {
              current
            }

          }
        }
      }
      countryOverviewGallery : _rawCountryOverviewGallery(resolveReferences: {maxDepth: 4})
      metadata: _rawCountryOverviewMetadata
    }
  }
`
